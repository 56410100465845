import axios from 'axios'
import { getJwtString } from '../../components/Auth'
import API from '../../components/API'
import querystring from 'querystring'

axios.defaults.headers.common['Content-Type'] = 'application/json'

/**
 * @param {Object} args - Argumentos a pasar en query
 * @returns {Promise}
 */
export function getPopulations (args) {
  const params = querystring.stringify(args)

  return axios.get(API('populations', params), {
    headers: {
      Authorization: getJwtString()
    }
  })
}
